import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initProductsSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=product-row', initProductsSlider)
}

function initProductsSlider(){
  const slider = $('.js-products-slider');

  if (slider.length) {
    slider.not('.slick-initialized').slick({
      arrows: true,
      dots: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
    });
  }
}
