import $ from 'jquery'
import 'slick-carousel'

$(document).on('click', '.product-item .js-open-modal', function (event) {
  let item = $(this).parent().parent().parent().parent().next();
  let slideshow = item.find('.js-product-img-slider');
  if (slideshow.length) {
    slideshow.not('.slick-initialized').slick({
      arrows: true,
      dots: true,
      adaptiveHeight: true
    });
  }
});

if ( $('body').hasClass('single-product') ) {
  let slideshow = $('.js-product-img-slider');
  if (slideshow.length) {
    slideshow.not('.slick-initialized').slick({
      arrows: true,
      dots: true,
      adaptiveHeight: true
    });
  }
}

if ( $('.product-row__feature-single').length ) {
  let slideshow = $('.product-row__feature-single .js-product-img-slider');
  if (slideshow.length) {
    slideshow.not('.slick-initialized').slick({
      arrows: true,
      dots: true,
      adaptiveHeight: true
    });
  }
}