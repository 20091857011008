import $ from 'jquery'

$(document).ready( initGallerySlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=image-gallery', initGallerySlider)
}

function initGallerySlider(){
  const sliderWrapper = $('.js-gallery-slider');
  if (sliderWrapper.length) {
    sliderWrapper.not('.slick-initialized').slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      accessibility: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
}