import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initMainSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=slider', initMainSlider)
}

function initMainSlider(){
  const slider = $('.js-slider');

  if (slider.length) {
    slider.not('.slick-initialized').slick({
      arrows: false,
      dots: true,
      fade: true,
    });
  }
}
