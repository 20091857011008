import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initPostsSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=featured-posts', initPostsSlider)
}

function initPostsSlider(){
  const slider = $('.js-posts-slider');

  if (slider.length) {
    slider.not('.slick-initialized').slick({
      arrows: true,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
    });
  }
}
