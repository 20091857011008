import $ from 'jquery'

function copyEmail() {
  // Get data-email attribute from button
  let email = $(this).data('email');

  // Copy email to clipboard
  let $temp = $("<input>");
  $("body").append($temp);
  $temp.val(email).select();
  document.execCommand("copy");
  $temp.remove();
  const buttonText = $(this).find('.offices-list__item-email-copy-text');

  // Change button text
  buttonText.text('Copied!');

  // Change button text back to original after 2 seconds
  setTimeout(function () {
    buttonText.text('Copy');
  }, 2000);
}

$(document).on('click', '.js-copy-email', copyEmail);
