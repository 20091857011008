import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)

function openModal( target ) {
  $.magnificPopup.open({
    items: {
      src: target,
      type: 'inline',
    },
    /* callbacks: {
      close: function(){
        const url = new URL( window.location )
        url.hash = ''
        history.pushState( {}, '', url )
      }
    }, */
    closeBtnInside: true,
    closeMarkup: '<button title="%title%" type="button" class="mfp-close"><span>Close</span><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5 5L1 9" stroke="black" stroke-linecap="round"/><path d="M9 9L5 5L9 1" stroke="black" stroke-linecap="round"/></svg></button>'
  });
}

$doc.ready( ()=>{
  const hash = window.location.hash
  if (hash && $(hash).length && $(hash).hasClass('mfp-hide') ){
    openModal( hash )
  }
  $doc.on('click', '.js-open-modal', e => {
    e.preventDefault()
    openModal( e.target.hash )
  } );
})
