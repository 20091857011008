import $ from 'jquery'

$(document).ready( initTestimonialsSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=testimonials', initTestimonialsSlider)
}

function initTestimonialsSlider(){
  const sliderWrapper = $('.js-testimonials-slider');
  if (sliderWrapper.length) {
    sliderWrapper.not('.slick-initialized').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      nextArrow: '<button type="button" class="slick-next"><svg width="15" height=45" viewBox="0 0 29 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 52L26 27L2 2" stroke="#fff" stroke-width="4"/></svg></button>',
      prevArrow: '<button type="button" class="slick-prev"><svg width="15" height="45" viewBox="0 0 29 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27 2L3 27L27 52" stroke="#fff" stroke-width="4"/></svg></button>',
    });
  }
}
