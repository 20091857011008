import $ from 'jquery'

$(document).ready( initMegaMenu )

function initMegaMenu(){

  if ($('.js-mega-menu').length) {
    $(window).on('resize', function(e) {
      $('.js-mega-menu').removeClass('mega-is-active mega-menu--mobile')
      $('.header__mega-menu').removeClass('mega-is-active')
      $('.header').removeClass('mega-is-active mega-menu-is-active')
    })
    $('.js-mega-menu').each( function() {
      const menuNode = this
      const menu_id = $(menuNode).data('menuId')
      // Desktop
      $(document).on('mouseenter mouseover keydown', `.nav--main #${menu_id}-desktop`, function(event) {
        event.type == 'keydown' && event.key === 'Enter' && event.preventDefault()
        if (event.key == 'Tab') return
        $('.header').addClass('mega-is-active mega-menu-is-active')
        $('.header__mega-menu').addClass('mega-is-active')
        $('.js-mega-menu').removeClass('mega-is-active')
        $(menuNode).addClass('mega-is-active')
        // Focus first item in submenu
        $($(menuNode).find('ul li a')[0]).trigger('focus')
      })
      $(document).on('keydown', '.js-mega-menu .grid__column a', function(event) {
        if (event.key != 'Tab') return
        const nextMenuId = $(this).closest('.js-mega-menu').next().data('menuId')
        const $lastGridGroup = $(this).closest('.grid').parent().find('.grid').last().find('.grid__column').last()
        const $lastLink = $lastGridGroup.is('UL') ? $lastGridGroup.find('li').last().find('a') : $lastGridGroup.find('.menu-post-item a')
        // Return to next main nav if last item in submenu
        this == $lastLink[0] && $(`.nav--main #${nextMenuId}-desktop`).trigger('keydown')
      })
      // Mobile
      $(document).on('click', `.js-mobile-nav.is-active .menu-item--mega-menu #${menu_id}`, function(e) {
        e.preventDefault()
        $('.header__mega-menu').addClass('mega-is-active header__mega-menu--mobile')
        $('.js-mega-menu').removeClass('mega-is-active')
        $(menuNode).addClass('mega-is-active mega-menu--mobile')
        $('.js-search-button.active').trigger('click')
      })
    } )
    $(document).on('mouseleave', '.header__mega-menu:not(.header__mega-menu--mobile)', function () {
      $('.header__mega-menu').removeClass('mega-is-active')
      $('.header').removeClass('mega-is-active mega-menu-is-active')
      $('.js-mega-menu').removeClass('mega-is-active')
    })
    document.body.addEventListener('keydown', function(e) {
      if (e.key == "Escape") {
        $('.header__mega-menu').removeClass('mega-is-active')
        $('.header').removeClass('mega-is-active mega-menu-is-active')
        $('.js-mega-menu').removeClass('mega-is-active')
      }
    })
    $(document).on('mouseenter', '.header__top-bar', function () {
      $('.header__mega-menu').removeClass('mega-is-active')
      $('.header').removeClass('mega-is-active mega-menu-is-active')
      $('.js-mega-menu').removeClass('mega-is-active')
    })
    $(document).on('click', '.js-mega-menu-close', function (e) {
      e.preventDefault()
      $('.header__mega-menu').removeClass('mega-is-active header__mega-menu--mobile')
      $('.js-mega-menu').removeClass('mega-is-active mega-menu--mobile')
    })
    $(document).on('click', '.js-hamburger', function () {
      $('.js-mega-menu-close').trigger('click')
    })
  }
}
